import '@/styles/normalize.css';
import '@/styles/webflow.css';
import '@/styles/starjack.webflow.css';
import Ticket from '@/styles/images/ticket.svg?react';
import Tickets from '@/styles/images/tickets.svg?react';
import Voucher1 from '@/styles/images/voucher_1.svg?react';
import Voucher from '@/styles/images/voucher.svg?react';
import GooglePlay from '@/styles/images/google.svg?react';
import AppStore from '@/styles/images/appstor_white.svg?react';
import StarjackKeycard from '@/assets/images/starjack-keycard-vorderseite.png';
import SpreadShopLogoWhite from '@/assets/images/merch/spreadshop_logo_white.png';
import { useNavigate } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';

import IconSnowboardMan from '@/assets/icons/home_new/icon-snowboard-man.svg?react';
import IconSkiDownhill from '@/assets/icons/home_new/icon-ski-downhill.svg?react';
import IconReturnKeycard from '@/assets/icons/home_new/icon-return-keycard.svg?react';
import IconKeycardFamily from '@/assets/icons/home_new/icon-keycard-family.svg?react';
import IconHourGlass from '@/assets/icons/home_new/icon-hourglass.svg?react';

import IconKeycardWhite from '@/assets/icons/home_new/icon-keycard-white.svg?react';

import IconStarjackApp from '@/assets/icons/Starjack-app.svg?react';

import Smartphone from '@/assets/images/smartphone.png';
import IconInsurance from '@/assets/icons/home_new/icon-insurance.svg?react';

import { useTranslation } from '@/hooks/useTranslation';

import { Button } from '@/components/Layout/Button/Button';

import { Theme } from '@/theme/theme';
import { HomeSearchCard } from '@/components/HomeSearchCard/HomeSearchCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGetLiftOperatorsQuery } from '@/api/liftOperator';
import { Box } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { AdvantageCard } from '@/components/PageContent/HomePageContent/AdvantageCard';
import { MerchSection } from '@/components/PageContent/HomePageContent/MerchSection';
import { usePageConfig } from '@/hooks/useConfig';
import { Image } from '@/components/Image/Image';

export const HomePage = () => {
    const navigate = useNavigate();
    const { isPortal } = usePageConfig();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated } = useTranslation();
    const matchesMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    useGetLiftOperatorsQuery();

    return (
        <>
            <div className='sidenavi-wrapper'>
                <div
                    data-w-id='58e95e58-6abf-3f9e-d7b7-c5f40a5de7be'
                    className='block-gutschein'
                >
                    <a
                        onClick={() => {
                            navigate(getPagePath(routerConfig.LiftOperators));
                        }}
                        target='_top'
                    >
                        <div className='div-block-34'>
                            <Tickets className='image-3 image-55' />
                            <div className='text-block'>
                                {getTranslated('LinkExternalKeycard.toTickets')}
                            </div>
                        </div>
                    </a>
                </div>
                <div
                    data-w-id='c331bdd8-f363-b260-bf15-e96372fbc801'
                    className='block-gutschein'
                >
                    <a
                        onClick={() => {
                            navigate(getPagePath(routerConfig.NewKeycardPage));
                        }}
                        target='_top'
                    >
                        <div className='div-block-34'>
                            <Ticket className='image-3 image-5' />
                            <div className='text-block'>
                                {getTranslated('common.orderKeycard')}
                            </div>
                        </div>
                    </a>
                </div>
                <div
                    data-w-id='9ba58cc9-c8d6-765b-0482-9cec7204f6dc'
                    className='block-gutschein'
                >
                    <a
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Vouchers));
                        }}
                        target='_top'
                    >
                        <div className='div-block-34'>
                            <Voucher1 className='image-3 image-5' />
                            <div className='text-block'>
                                {getTranslated('Core.buyVoucher')}
                            </div>
                        </div>
                    </a>
                </div>

                {isPortal && (
                    <div
                        data-w-id='58e95e58-6abf-3f9e-d7b7-c5f40a5de7be'
                        className='block-gutschein'
                    >
                        <a
                            href='https://starjackmerch.myspreadshop.de/'
                            target='_top'
                        >
                            <div className='div-block-34'>
                                <Image
                                    sx={{
                                        width: 50,
                                        height: 50,
                                    }}
                                    src={SpreadShopLogoWhite}
                                />

                                <div className='text-block'>
                                    {getTranslated('sidebar.merch.title')}
                                </div>
                            </div>
                        </a>
                    </div>
                )}
            </div>
            <div
                data-w-id='50c1984d-c352-64ed-0261-8920b263d7ce'
                className='hero-app-icons'
            >
                <a
                    href='https://play.google.com/store/apps/details?id=at.starjack'
                    target='_blank'
                    className='w-inline-block'
                    rel='noreferrer'
                >
                    <GooglePlay className='image-14' />
                </a>
                <a
                    href='https://apps.apple.com/at/app/starjack-online-skitickets/id1419959563'
                    target='_blank'
                    className='w-inline-block'
                    rel='noreferrer'
                >
                    <AppStore className='image-14' />
                </a>
            </div>
            <div className='hero-header'>
                <div className='header-wrapper'>
                    <div className='form-block w-form'>
                        <div className='form'>
                            <h1 className='heading-jumbo'>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: getTranslated(
                                            'HomePage.headline'
                                        ),
                                    }}
                                />
                            </h1>
                            <h3 className='text-span-3'>was willst erleben?</h3>
                            <h1 className='heading-8'>
                                Biken - Wandern – Gletscherski – Biken - Wandern
                                – Gletscherski – Gletscherski Biken - Wandern –
                                Gletscherski – Biken – Wandern – Gletscherski
                                <br />
                            </h1>
                            <h3
                                style={{
                                    maxWidth: 1400,
                                }}
                                data-w-id='b8ec7784-36f4-4a52-3a24-a5f956972252'
                                className='heading-9'
                            >
                                {getTranslated('HomePage.subline')}
                                <br />
                            </h3>

                            <form
                                id='email-form'
                                name='email-form'
                                data-name='Email Form'
                                data-w-id='d975592b-6e66-b0f7-d8ed-34b226aa5d65'
                            >
                                {matchesMobile ? (
                                    <Button
                                        sx={{
                                            color: '#fff',
                                        }}
                                        variant='contained'
                                        color='secondary'
                                        onClick={() => {
                                            navigate(
                                                getPagePath(
                                                    routerConfig.LiftOperators
                                                )
                                            );
                                        }}
                                    >
                                        {getTranslated('Home.show.allOffers')}
                                    </Button>
                                ) : (
                                    <HomeSearchCard />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <div className='helmet' />
            </div>
            <div className='box-section'>
                <div className='div-block-77'>
                    <h2 className='h2-heading dark'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getTranslated(
                                    'HomePageWinter.headline'
                                ),
                            }}
                        />
                    </h2>
                    <p className='paragraph-copy'>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTranslated('HomePageWinter.text'),
                            }}
                        />
                        <br />
                        <br />
                        <span className='text-span-5'>
                            {getTranslated('HomePageWinter.subText')}
                        </span>
                    </p>
                    <a
                        onClick={() => {
                            navigate(getPagePath(routerConfig.MyKeycards));
                        }}
                        target='_top'
                        className='button-2 dunkel w-button'
                    >
                        {getTranslated('HomePage.getKeycard')}
                    </a>
                </div>
                <div className='box-container'>
                    <div className='box-controller'>
                        <div
                            className='box-side center-side mfront'
                            style={{ border: 'none' }}
                        >
                            <img src={StarjackKeycard} />
                        </div>
                    </div>
                </div>
            </div>
            <Box
                sx={{
                    pb: 20,
                }}
                className='vorteileWrapper'
            >
                <h2 className='h2-heading'>
                    {getTranslated('HomePage.advantagesHeadline')}
                </h2>

                <FlexContainer
                    sx={(theme) => ({
                        flexWrap: 'wrap',
                        gap: 10,
                        mt: 10,
                        mx: 'auto',
                        maxWidth: '60%',
                        justifyContent: 'center',
                        flexGrow: 1,

                        [theme.breakpoints.down('sm')]: {
                            maxWidth: 'unset',
                            px: 6,
                        },
                    })}
                >
                    <AdvantageCard
                        Icon={<IconSnowboardMan />}
                        title={'Eine Keycard für hunderte Bergbahnen und Lifte'}
                        description={
                            'Auf deine (starjack-)Keycard buchst du kontaktlos und sekundenschnell alle Tickets, die dir unsere Bergbahnen online anbieten. Von der Stunden- bis zur Saisonkarte.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconSkiDownhill />}
                        title={'Dein Ticket ist sofort einsatzbereit'}
                        description={
                            'Dein bei starjack gebuchtes Ticket ist wenige Sekunden nach der Bestellung schon aktiviert (Bestätigung per E-Mail) und du kannst direkt zum Lift gehen, ohne zu warten.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconStarjackApp />}
                        title={'Flexible Buchungsoptionen auch ohne Keycard'}
                        description={
                            'Unsere Bergbahnen bieten, je nach deren Möglichkeiten, auch Buchungsoptionen ohne Keycard an; den Pickup Voucher zur Abholung vor Ort oder das elektronische Zutrittsticket (eTicket) für den direkten Liftzugang mit dem Handy. Einfach Ticket in den Warenkorb legen und aus den von der Bergbahn aktivierten Buchungsoption die passende auswählen.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconInsurance />}
                        title={'Versicherung Safety+ / Refund+'}
                        description={
                            'Wünschen Sie Unfallschutz auf der Piste? Falls etwas passiert, erhalten Sie unter anderem das Geld für Skipass und Skikurs zurück.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconReturnKeycard />}
                        title={'Rückgabe deines Tickets möglich'}
                        description={
                            'Dein Ticket kannst du unter bestimmten Bedingungen zurückgeben, insofern es im Webshop nicht als „nicht stornierbar“ ausgewiesen ist. In deinem Webshop-Konto, Menü „meine Tickets“, stehen dir zwei Rückgabeoptionen zur Verfügung, zwischen denen du wählen kannst.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconKeycardWhite />}
                        title={'Alten Skipass zur Onlinebuchung registrieren'}
                        description={
                            'Du hast noch eine alte Keycard zuhause? Registriere diese in deinem Webshop-Konto und du kannst sofort Tickets darauf buchen – so oft du willst. Alternativ kannst du eine starjack-Keycard bestellen.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconStarjackApp />}
                        title={'Keycard wiederaufladbar mit Tickets'}
                        description={
                            'Über dein starjack-Konto kannst du beliebig oft Tickets auf deine Keycard aufbuchen. Die starjack-Keycard kannst du mehrere Jahre hinweg nutzen.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconKeycardFamily />}
                        title={'1 Webshop-Konto für Freunde & Familie'}
                        description={
                            'In deinem starjack-Konto kannst du beliebig viele Keycards durch Registrieren oder Bestellen hinterlegen und Tickets darauf buchen. Ideal für Familien.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconStarjackApp />}
                        title={'starjack-App'}
                        description={
                            'Mit der kostenfreien starjack-App geht das mobile Ticketbuchen noch schneller, noch komfortabler. Für iOS und Android verfügbar.'
                        }
                    />

                    <AdvantageCard
                        Icon={<IconHourGlass />}
                        title={'Wertvolle Zeit genießen'}
                        description={
                            'Denn darum gehts: Um deine genussvolle Zeit! Wir machen das Ticketbuchen für dich einfach und bequem. Wann und wo du willst. Öffnungszeiten oder Warteschlangen an Ticketkassen gibts für dich nicht mehr – du bist starjacker!'
                        }
                    />
                </FlexContainer>
            </Box>
            <div className='box-section'>
                <div className='div-block-77'>
                    <Voucher className='image-6' />
                    <h2 className='h2-heading dark'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getTranslated(
                                    'HomePage.voucherHeadline'
                                ),
                            }}
                        />
                    </h2>
                    <p className='paragraph-copy'>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTranslated('HomePage.voucherText'),
                            }}
                        />
                    </p>
                    <a
                        onClick={() => {
                            navigate(getPagePath(routerConfig.Vouchers));
                        }}
                        target='_top'
                        className='button-2 dunkel w-button'
                    >
                        {getTranslated('HomePage.voucherLink')}
                    </a>
                </div>
            </div>
            <div
                className='_3d-section-2'
                style={{
                    marginBottom: 0,
                }}
            >
                <div className='block-phone'>
                    <div
                        style={{
                            width: '600px',
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                            }}
                            src={Smartphone}
                        />
                    </div>
                </div>
                <div className='content-block'>
                    <div className='div-block-43'>
                        <h1 className='heading-12-copy'>
                            {getTranslated('HomePage.sjAppTitle')}
                        </h1>
                        <p className='paragraph white left'>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getTranslated('HomePage.sjAppText'),
                                }}
                            />
                        </p>
                        <div className='div-block-48'>
                            <a
                                href='https://play.google.com/store/apps/details?id=at.starjack'
                                target='_blank'
                                className='w-inline-block'
                                rel='noreferrer'
                            >
                                <GooglePlay className='image-14' />
                            </a>
                            <a
                                href='https://apps.apple.com/at/app/starjack-online-skitickets/id1419959563'
                                target='_blank'
                                className='w-inline-block'
                                rel='noreferrer'
                            >
                                <AppStore className='image-14' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {isPortal && <MerchSection />}
        </>
    );
};
